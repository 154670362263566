import React from "react";
import Conclusion from "../../components/project/Conclusion";
import CommonProjectComponent from "../../components/project/CommonProjectComponent";
import ProblemSolution from "../../components/project/ProblemSolution";
import ProjectDetailBanner from "../../components/project/ProjectDetailBanner";
import PreviousNext from "../../components/PreviousNext";
import { Seo } from "../../components/seo";
import Layout from "../../components/layout";

const AutoService = () => {
  const headingbanner = {
    title: `Auto Service Booking`,
    content: `A CRM Platform For Car Servicing`,
  };

  const data = {
    images: ["auto-service-web.webp", "auto-service-web-2.webp"],
    firstAlt: "UI Screen To Schedule For Car Servicing",
    firstTitle: "Calender",
    secondAlt: "UI Dashboard To Overview Car Servicing Performance",
    secondTitle: "Performance Dashboard",
  };

  const problemSolutionContent = {
    sidebar: [
      {
        title: "Industry",
        text: ["SaaS"],
      },
      {
        title: "What we did",
        text: [
          "Product Development",
          "UI UX Designing",
          "User Consultation",
        ],
      },
      {
        title: "Platform",
        text: ["Web Application"],
      },
    ],
    mainContent: [
      {
        title: "Problem",
        content: [
          `The company wanted an end-to-end solution to coordinate and manage the service booking of the automotive.`,
          `They wanted to improve the platform’s functionality and streamline their service offerings to offer improved customer experience. However, there were several problems that have to be looked upon.`,
          `The integration challenge hampers the platform to connect with other systems, such as inventory management, customer databases, or payment gateways.`,
          `We also had to look into the usability issues to offer an intuitive interface to simplify the servicing process.`
        ],
      },
      {
        title: "Solution",
        content: [
          `Through our solutions, we designed a platform to offer intuitive and easy navigation and straightforward workflows to improve the overall productivity of the team. `,
          `We also seamlessly integrated the platform with other systems to ensure smooth data synchronisation and real-time updates with inventory management, customer databases, and payment gateways. `,
          `We created a flexible and scalable solution that can monitor live performance and offer software updates to enhance the platform's stability and overall user experience. `,
        ],
      },
    ],
    image: ["auto-service-problem.webp"],
    firstAlt: 'All Mockup Screens',
    firstTitle: 'Project Screens',
  };

  const projectData = {
    title: "Dashboard",
    para: [
      `We created a dashboard that shows the calendar-wise service booking for the automotive to keep track of the slot availability. `,
    ],
    content: {
      imageAlt: 'UI Screen To Schedule For Car Servicing',
      imageTitle: 'Calender',
      image: ["auto-service-projectone.webp"],
      text: `Consectetur nulla, odio facilisis tortor. odio turpis nec elit Cras ultrices non ullamcorper consectetur consectetur hendrerit elit elit ipsum In vel nisl. `,
    },
    content2: {
        image: ["auto-service-projectone-2.webp"],
        text: `The company details show the complete information of the dealers to facilitate improved service offerings. `,
      },
  };

  const projectData2 = {
    title: `Overview`,
    para: [
      `We also added periodical filters in the dashboard which allows representatives to look into specific customer details with respect to any particular date.`,
    ],
    content: {
      image: ["auto-service-projecttwo.webp"],
      imageAlt: 'Check company details and their branches',
      imageTitle: 'Company Details',
    },
  };

  const projectData3 = {
    title: "More Information ",
    para: [
      `The dashboards below show other options that we added to improve the overall productivity of the team like the PSF column which helps them keep track of the post-service offerings to the customers, marketing messages sent to them, SMS logs etc. `,
    ],
    content: {
      image: [
        "auto-service-projectthree.webp",
        "auto-service-projectthree-2.webp",
        "auto-service-projectthree-3.webp",
        "auto-service-projectthree-4.webp",
        "auto-service-projectthree-5.webp",
      ],
      firstAlt: 'Calendar to book and overview the appointment schedule',
      firstTitle: 'Appointment Overview',
      secondAlt: 'Check all customer details',
      secondTitle: 'Customers',
      thirdAlt: 'In-built template for SMS Marketing',
      thirdTitle: 'SMS Marketing',
      fourthAlt: 'List of customers for PSF',
      fourthTitle: 'Post-Service Follow-up',
      fifthAlt: 'List of users',
      fifthTitle: 'Users List',
      sixthAlt: 'UI Screen to check SMS logs',
      sixthTitle: 'SMS Logs',
    },
  };

  const conclusionData = [
    {
      para: [
        `We provided an end-to-end solution to the company. Our aim was to improve the overall usability of the platform and increase productivity.`,
        `When the team reached out to us there were several challenges that had to be addressed to keep them updated, improve their platform and service offerings for improved customer experience.`,
        `We provided them with solutions such as multi-platform integration, improved usability, seamless navigation and many more to help them align them with their business goals. `,
      ],
    },
  ];

  return (
    <Layout>
      <div className="service-page">
        <ProjectDetailBanner data={data} headingbanner={headingbanner} />
        <ProblemSolution data={problemSolutionContent} />
        <CommonProjectComponent data={projectData} mode="cream" />
        <CommonProjectComponent data={projectData2} />
        <CommonProjectComponent data={projectData3} mode="dark" />
        <Conclusion conclusionData={conclusionData} />
        <PreviousNext currentProjectId={19} />
      </div>
    </Layout>
  );
};

export default AutoService;

export const Head = () => (
  <Seo title="Auto Service Booking - Octet Design Studio" description="We created a CRM platform for automobile booking based on US by offering our services on Product Development, UI UX Designing & User Acquisition Consultation." />
)